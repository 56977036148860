import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/alliance/[id=number]": [~19],
		"/auth/eve/success": [11],
		"/battle/kill/[id=number]": [12],
		"/campaigns": [8],
		"/campaigns/[id]": [~9],
		"/character/[id=number]": [~20],
		"/corporation/[id=number]": [~21],
		"/docs/apidocs": [25],
		"/docs/stomp": [24],
		"/faq": [17],
		"/information": [7],
		"/item/[id=number]": [23],
		"/kills/[type=killlistTypes]": [13],
		"/kill/[id=number]": [~10],
		"/lists": [18],
		"/littlehelper/dscan": [15],
		"/littlehelper/localscan": [14],
		"/littlehelper/pilotlookup": [16],
		"/search/[query]": [22],
		"/stats": [6]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';